.header-strip {
  // height: 25%;
  left: -40px;
  padding: 8px 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 997;

  .header--menu {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      float: right;
      margin-left: 14px;
      position: relative;

      a {
        border-radius: 50%;
        display: block;
        height: 44px;
        line-height: 44px;
        position: relative;
        text-align: center;
        transition: background-color .3s;
        width: 44px;

        img {
          border-radius: 50%;
          height: 39px;
          margin-top: 12px;
          object-fit: contain;
          width: 39px;
        }
      }

      p {
        color: #5F5F5F;
        font-size: 20px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 600;
        height: 24px;
        letter-spacing: normal;
        line-height: normal;
        margin-left: 10px;
      }

      .switch-label {
        color: #32455F;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
        padding: 21px 10px 0 0;
      }

      .switch-local {
        align-self: center;
        margin: 12px 20px 0 0;
        width: 121px;
      }

      .p-dropdown-panel {
        li {
          width: 100%;
        }

      }

      .p-dropdown {
        width: 100%;
      }

    }

    .user-profile {
      cursor: pointer;
    }
  }

  .p-dropdown-label {
    color: #424242;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
  }
}
