@import 'theme';
@import '../node_modules/primeicons/primeicons.css';
@import 'common';
@import 'forms';

@import 'shared/components/header.component';
@import 'shared/components/page-header.component';
@import 'shared/components/sidebar.component';
@import 'shared/directives/search-location.directive';


@import 'app/pages/sign-in.component';

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), url('./assets/fonts/SourceSansPro-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), url('./assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), url('./assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'), url('./assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}
