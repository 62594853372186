@import 'theme';

* {
  box-sizing: border-box;
}

body {
  background-color: $bodyBgColor;
  color: $BodyTextColor;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: $fontSize;
  margin: 0;
  padding: 0;
}

.text-center {
  text-align: center;
}

.mrg-top-10 {
  margin-top: 10px;
}

.image-code {
  background: url('/assets/images/temp/sprite.png') 0 0;
}

.image-rating-width {
  height: 25px;
  width: 25px;
}

.rating1 {
  background-position: -69px -38px;
}

.rating2 {
  background-position: -119px -38px;
}

.rating3 {
  background-position: -168px -38px;
}

.rating4 {
  background-position: -219px -38px;
}

.rating5 {
  background-position: -269px -38px;
}

.track-driver {
  cursor: pointer;
  float: right;

  &:hover {
    color: $defaultThemeColor;
  }
}

input {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}


a {
  color: $linkColor;

  &:link,
  &:active,
  &:focus {
    color: $linkColor;
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: $stateFocusBgColor;
    outline: none;
    text-decoration: none;
  }
}

.main-panel {
  background-color: $mainContentBgColor;
}

.page-header {
  background-color: $pageHeaderBgColor;
}

.page-buttons {
  button {
    float: right;
    margin: 0 0 0 8px;
  }
}

.ql-editor {
  background: $white;
}

p-tabmenu {
  .ui-widget-content {
    border: 0 none;
  }
}

ul {
  &.ui-widget-header {
    border: 0 none;

    .ui-tabmenuitem {
      a {
        color: $inputTextColor;
      }

      &.ui-state-active {
        a {
          color: $gridHeaderTextColor;
        }
      }
    }
  }
}

.ui-tabmenu-nav {
  background: $stateDefaultBgColor;
  border: 0;

  li {
    &.ui-tabmenuitem {
      background-color: $stateDefaultBgColor;

      &:not(.ui-state-active) {
        background-color: $sideBarHoverBgColor;

        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $stateDefaultBorderColor;

          a {
            color: $sidebarIconColor;
          }
        }

        a {
          color: $sidebarIconColor;
        }
      }
    }
  }
}

.panel-div {
  background-color: $panelDivBgColor;
  padding: 8px;
}

.panel-div-top-info {
  padding: 0 0 8px;

  .left-info {
    text-align: left;
  }

  .right-info {
    text-align: right;
  }
}

h1 {
  &.page-title {
    font-size: 18px;
  }
}

.bread-crumbs {
  float: right;
  padding: 14px 5px;
}

.bread-item {
  display: inline-block;
  list-style-type: none;
}

.ui-panel {
  .ui-widget-header {
    font-size: $headerFontSize;
    font-weight: bold;
  }

  .ui-panel-titlebar {
    padding: 8px 16px;
  }

  .ui-panel-content {
    background: none;
    border: 0;
    padding: 8px 16px;
  }
}

.ui-button {

  &.ui-state-default {
    background: $buttonDefaultBgColor;
    border: 1px solid $buttonDefaultBgColor;
    color: $buttonDefaultTextColor;

    &:hover {
      background: $buttonHoverColor;
      border: 1px solid $buttonHoverColor;
    }
  }

  &.negative-button {
    background-color: $negativeButtonColor;
    border: 1px solid $negativeButtonColor;

    &:hover {
      background-color: $negativeButtonHoverColor;
      border: 1px solid $negativeButtonHoverColor;
    }
  }

  &.ui-button-danger {
    &:enabled {
      &:hover {
        background-color: $stateFocusBgColor;
      }
    }

    &:focus {
      background-color: $stateFocusBgColor;
    }
  }
}

.ui-button-text-only {
  .ui-button-text {
    padding: 8px 16px;
  }
}

.ui-button-text-icon-left {
  font-size: 18px;

  .ui-button-text {
    font-size: 13px;
    padding: 8px 10px 8px 36px;
  }
}

.ui-inputtext {
  &.ui-state-default {
    background: $inputFieldsBgColor;
    border: 1px solid $inputFieldBorderColor;
    color: $contentTextColor;
    padding: 12px 16px;
  }

  &:enabled {
    &:hover {
      border: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }

    &:focus {
      border: 1px solid $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.ui-growl-item-container {
  &.ui-state-highlight {
    &.ui-growl-message-success {
      background-color: $growlMessageSuccessColor;
      border-color: $growlMessageSuccessColor;
    }

    &.ui-growl-message-error {
      background-color: $growlMessageErrorColor;
      border-color: $growlMessageErrorColor;
    }
  }
}

.partner-box {
  background: $white;
  border: 1px solid $inputFieldBorderColor;
  color: $headerTextColor;
  margin-bottom: 20px;
}

.out-partner-info {
  cursor: pointer;
  text-align: right;
}

.lang-right-to-left {

  .form-label,
  a,
  .ui-panel-titlebar,
  p {
    direction: rtl;
  }

  input {
    &[type='text'] {
      direction: rtl;
    }
  }

  .ui-panelmenu {
    .ui-menuitem-icon {
      &.fa {
        margin-left: .5em;
      }
    }
  }
}

.assign {
  .ui-inputtext {
    &.ui-state-default {
      height: 32px;
      padding: 0 10px;
      width: 100%;
    }
  }

  .ui-autocomplete {
    width: 100%;

    .ui-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 9px;
      vertical-align: top;
      width: 22px;
    }
  }

  &.search-driver {
    .ui-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 1px;
      vertical-align: top;
      width: 22px;
    }
  }
}

.web-link {
  color: $stateDefaultTextColor;
}

.caps {
  text-transform: uppercase;
}

.map {
  height: 100%;
  width: 100%;
}

.main-container {
  margin-left: 250px;
  padding-top: 60px;
  transition: left .3s;

  .main-content {
    padding: 16px 32px;
  }
}

.sidebar {
  left: 0;
  transition: left .3s;
  width: 250px;
}


// .eclipse {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;

//   div {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
// }

.closed {
  .sidebar {
    left: -250px;
    transition: left .3s;
  }

  .main-panel {
    width: 100%;
  }

  .main-container {
    margin-left: 0;
    padding-top: 60px;
    transition: left .3s;

    .main-content {
      padding: 16px;
    }
  }
}

.mi-pie-chart {
  display: inline-block;
  height: auto;
  width: 250px;
}

.mi-bar-chart {
  display: inline-block;
  height: 271px;
  width: 60%;
}

.flexbox {
  flex: 1;
  height: 233px;
}

.error {
  color: $defaultThemeColor;
  font-size: 16px;
}

.details {
  display: flex;
}

.top-padding {
  padding-top: 3%;
}

.document {
  cursor: pointer;
}

.loader-hidden {
  visibility: hidden;
}

.loader-page-center-overlay {
  background-color: $loaderBgColor;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 88px;
  z-index: 500000;
}

.info-label {
  color: #3D4246;
  display: inline-block;
  font-weight: bold;
}

.info-data {
  display: inline-block;
}

.device-height {
  min-height: 100vh;
}

.add-edit-panel {
  padding: 30px 20px 20px;
}

.ui-button-icon-only {
  .ui-button-text {
    margin-top: -2px;
  }
}

.ui-autocomplete {
  .ui-autocomplete-dropdown {
    height: 30px;
    top: -1px;
    vertical-align: inherit;
  }
}

.user-pic {
  margin: 5% 15%;
  width: 70%;
}

p-calendar {
  .p-calendar {
    max-width: 107%;
    width: 107%;

    .p-inputtext {
      height: 34px;
      width: 100%;
    }
  }
}

.ui-spinner {
  .ui-spinner-up {
    font-size: 12px;
    height: 14px;
    margin: 8px 0 0;
  }

  .ui-spinner-down {
    font-size: 12px;
    height: 14px;
    margin: 0;
    top: 24px;
  }
}

.inner-container {
  padding: 0 15px;
}

.gridparent {
  margin: 0 6px;
  padding: 0;
}

.img-doc {
  max-width: 60px;
}

.active-box {
  background: $white;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, .33);
  color: $activeBoxTextColor;
  margin-right: 20px;
  padding: 7px 15px;
  text-align: left;

  .special-count {
    float: right;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.form-buttons {
  &.corporate-button-bar {
    margin-top: 60px;
  }

  button {
    &.destination-add-full-btn {
      margin: 0;
      width: 100%;
    }
  }
}

.reset-btn {
  background-color: $white;
  border: 1px solid $inputFieldBorderColor;
  border-radius: 4px;
  color: $defaultThemeColor;
  cursor: pointer;
  float: left;
  margin-top: 7px;
  padding: 6px 10px;
}

p-dropdown {
  .ui-dropdown-items {
    .ui-state-highlight {
      background: $dropdownBgColor;
      border-color: $dropdownBorderColor;
      color: $white;
    }
  }

  .ui-dropdown {
    &:not(.ui-state-disabled) {
      &:active {
        border-color: $inputFieldBorderColor;
      }

      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .ui-dropdown-trigger {
      .fa {
        color: $defaultThemeColor;
      }
    }
  }

  .ui-inputtext {
    &:focus {
      border-color: $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.ui-chkbox-box,
.ui-radiobutton-box {
  &.ui-state-default {
    background: $checkboxBackgroundColor;
    border-color: $checkboxActiveBorderColor;
    color: $checkboxCheckColor;
  }

  &:not(.ui-state-disabled) {
    &:not(.ui-state-active) {
      &:hover {
        background-color: $checkboxBackgroundColor;
        border-color: $checkboxActiveBorderColor;
      }
    }
  }

  &.ui-state-active {
    border-color: $checkboxActiveBorderColor;
  }
}

.form-field {

  .asterisk {
    color: $growlMessageErrorColor;
  }

  .employee-input {

    input {

      &[type='text'] {
        width: 100%;
      }
    }
  }

  .ui-dropdown-panel {
    input {
      &[type='text'] {
        width: 100%;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .ui-dropdown-filter-container {
      width: 100%;

      .fa {
        position: absolute;
        right: 21px;
        top: 21px;
      }
    }

    .ui-dropdown-item {
      &:not(.ui-state-highlight) {
        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $secondaryThemeColor;
        }
      }
    }
  }
}

.delete-bin {
  &.fa {
    cursor: pointer;
    float: right;
    font-size: 16px;

    &:hover {
      color: $defaultThemeColor;
    }
  }
}

.document-container {
  width: 100%;
}

.ui-datepicker {
  .ui-datepicker-calendar {
    td {
      &:not(.ui-state-disabled) {
        a {
          &.ui-state-highlight {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
            color: $white;
          }

          &.ui-state-active {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
            color: $white;
          }

          &:hover {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
          }
        }
      }
    }
  }
}

.scroll-panel {
  overflow: scroll;
  padding: 0 0 16px;
}

.box-bottom-border {
  border-bottom: 1px solid $sidebarIconColor;
}

.mt-10 {
  margin-top: 10px;
}

.step-tab {
  .ui-steps-item {
    &.ui-state-highlight {
      background-color: $secondaryThemeColor;

      a {
        color: $white;

        &:focus {
          color: $white;
        }
      }

      &:hover {
        a {
          color: $white;
        }
      }
    }

    &.ui-state-disabled {
      a {
        color: $secondaryThemeColor;
      }
    }
  }
}

p-multiselect {
  .ui-multiselect {
    &:not(.ui-state-disabled) {
      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .ui-multiselect-trigger {
      border: 0 none;
      color: $defaultThemeColor;
    }
  }
}

p-selectbutton {
  .ui-selectbutton {
    .ui-button {
      &:not(.ui-state-disabled) {
        &:not(.ui-state-active) {
          &:hover {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
          }
        }
      }
    }
  }
}

p-chips {
  .ui-chips {
    .ui-inputtext {
      &:hover {
        border-color: $inputFieldBorderColor;
      }

      .ui-chips-token {
        &.ui-state-highlight {
          background-color: $defaultThemeColor;
          border-color: $defaultThemeColor;
        }
      }

      .ui-chips-input-token {
        input {
          &[type='text'] {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.right-align {
  padding-right: 3%;
  text-align: right;
}

.same-height-div {
  display: flex;

  .beside-div-same-height {
    background: $white;
    flex: 1;
    margin: 0 .5em .5em;
    padding: 0;
  }
}

p-dialog {
  .ui-dialog {
    &.ui-corner-all {
      border: 0;
    }

    .ui-dialog-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $white;
      font-size: 16px;
      padding: 1em 1.1em;
      position: relative;

      .ui-dialog-titlebar-close {
        border: 0;
        color: $white;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .ui-dialog-footer {
      overflow: hidden;
      padding: .8em;
    }
  }
}

.access-checkbox-div {
  display: inline-block;
  padding: 5px;
}

.access-editbtn-div {
  display: inline-block;
  padding: 5px;
}

.department-autocomplete {
  margin-top: 4%;

  .ui-inputtext {
    input {
      &[type='text'] {
        border: 0 none;
      }
    }
  }

  .ui-state-default {
    padding: 10px;
  }

  .ui-autocomplete-panel {
    width: 96%;
  }

  ul {
    width: 96%;

    li {
      font-size: 14px;
    }
  }
}

.faq-form {
  margin-bottom: 30px;
}

.faq-panel-marin {
  margin-bottom: 2%;

  .content {
    padding-bottom: 2%;
    text-align: justify;
  }

  .footer {
    text-align: right;
  }

  p-header {
    font-size: 14px;
    padding: 0;
  }

  &.ui-panel {
    padding: 0;

    .ui-panel-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $sidebarIconColor;
    }

    .ui-panel-titlebar-icon {
      color: $secondaryThemeColor;
      font-size: 13px;
      margin-top: 5px;

      span {
        margin-top: 3px;
      }
    }

    .ui-panel-content {
      padding: 16px;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
}

.term-conditions {
  padding: 45px;

  h3 {
    color: $sideBarHoverBgColor;
    font-size: 17px;
    margin: 0;
  }
}

.ql-align-justify {
  color: $secondaryThemeColor;
  font-size: 15px;
  margin: 10px 0 45px;
  text-align: justify;
}

.driverassigndropdown {

  .ui-autocomplete {
    width: 100%;

    input {
      &[type='text'] {
        border: 1px solid #E1E3E7;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
        height: 32px;
        margin: 7px 0;
        padding: 0 2%;
        width: 94%;
      }
    }

    .ui-autocomplete-dropdown {
      border: 0 none;
      position: absolute;
      right: 2px;
      top: 8px;
      vertical-align: top;
    }
  }

  .driver-name {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: left;
    width: 23%;
  }

  .driver-ref-no {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: center;
    width: 35%;
  }

  .driver-eta {
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: right;
    width: 26%;
  }
}

.trip-details {
  .pickup-circle {
    color: $mapPickup;
  }

  .drop-circle {
    color: $mapDrop;
  }

  .one-line-block {
    float: right;
    margin-left: 20px;
  }

  .ui-fileupload-buttonbar {
    padding: 0 0 0 10px;
  }
}

.assign-btn {
  float: right;
  margin: 0 15px 9px 0;
}

.attached-docs {
  fieldset {
    margin: 20px 0;
  }

  ul {
    margin: 0;
    padding: 0;

    .panel-div {
      padding: 0 15px;
    }
  }
}

.destination-box {
  background: $destinationBox;
  border-bottom: 1px solid $secondaryThemeColor;
  overflow: auto;
}

.forgot-password-link {
  cursor: pointer;
}

.rental {
  .destination-box {
    background: $destinationBox;
    border-bottom: 1px solid $secondaryThemeColor;
    display: block;
    min-height: 186px;
    overflow: auto;
  }
}

.pricing-bundle {
  min-height: 196px;
}

.fa-trash {
  cursor: pointer;
}

.fa-pencil {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.at-bottom {
  bottom: 0;
  position: absolute;
}

.assign-search-input-layout {
  margin: 15px 0 10px;
}

.type-of-passenger {
  margin-top: 10px;
}

.driver-eta {
  float: right;
}

.m-20 {
  margin: 20px;
}

.emergency-note {
  color: $defaultThemeColor;
}

.route-map-height {
  height: 446px;
}

.disabled-row {
  background: $stateDefaultBorderColor;
}

.enabled-row {
  background: $white;
}

// To display erros
.form-errors {
  color: #F00;
  font-size: 10px;
  padding-top: 5px;
}

:host ::ng-deep {
  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page {
        &.p-highlight {
          background: #F6F6F6;
          ;
          border-color: #F6F6F6;
          ;
          color: #007AD9;
          font-weight: 600;
        }
      }
    }
  }

  .p-timepicker {
    .p-minute-picker {
      display: block;
    }
  }

}

input,
.p-dropdown,
.p-multiselect {
  height: 33px;
  width: 100%;
}

input[readonly] {
  background-color: #D3D3D3;
  border: 1px solid #212121;
  border-radius: 2px;
}
