.company-logo {
  background-color: $defaultThemeColor;
  border-radius: 0 48px 0 0;
  padding: 16px 0 40px;
  text-align: center;

  .ila-logo {
    height: 70px;
    object-fit: contain;
    padding-top: 10px;
    width: 70px;
  }

  .ila-logo-shrink {
    height: 50px;
    object-fit: contain;
    padding-top: 10px;
    width: 50px;
  }
}

.logout-topborder-expanded {
  width: 238px;
}

.logout-topborder-shrinked {
  width: 70px;
}


.logout-button {
  border-top: 1px solid #FFF;
  bottom: 0;
  height: 33px;
  margin-top: 18px;
  position: fixed;

  &:hover {
    background: #FFF;
    background-image: linear-gradient(to right, #75C5DE 2%, #FFF 72%);
    border-bottom-left-radius: 15px;
    border-color: #FFF;
    border-top-left-radius: 15px;
    color: #093A83;
    cursor: pointer;

    .logout-text {
      color: #093A83;
    }
  }

  .logout-text {
    bottom: 1.2%;
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
    left: 4.4%;
    position: fixed;
  }
}

.logout-img-expanded {
  left: 10.5%;
  position: relative;
  top: 18%;
}

.logout-img-shrinked {
  left: 38.5%;
  position: relative;
  top: 18%;
}

.ila-logo-expand {
  height: 85px;
  object-fit: contain;
  width: 232px;
}

.logout-container {
  .logout-icon {
    border-top: 1px solid #EEE;
    cursor: pointer;
    position: absolute;
    width: 100%;

    img {
      height: 24px;
      left: 30%;
      position: absolute;
      top: 10px;
      width: 24px;
    }
  }
}

.sidebar {
  background-color: $sidebarBgColor;
  // border-radius: 0 13px 0 0;
  height: 100%;
  margin-top: 8px;
  position: fixed;
  z-index: 999;
}


.p-panelmenu {
  .p-panelmenu-header>a .p-menuitem-icon {
    margin: 0 0 0 10px;
  }

  .p-panelmenu-content {
    background: #093A83;

    .p-menuitem {
      .p-menuitem-link {
        padding-left: 60px;

        &:not(.p-disabled) {
          &:hover {
            background-image: linear-gradient(to right, #75C5DE 2%, #FFF 72%);
          }
        }

        .p-menuitem-text {
          color: #FFF;
        }
      }

    }

  }

  .p-menuitem-text {
    margin-left: 10px;

  }

  .p-panelmenu-panel {
    margin-bottom: 18px;
  }

  .p-panelmenu-header {

    &:not(.p-highlight):not(.p-disabled) {
      a {
        &:hover {
          background-image: linear-gradient(to right, #75C5DE 2%, #FFF 72%);
        }
      }
    }

    a {
      background-color: #093A83;
      border: 0;
      border-color: #093A83;
      border-radius: 17px 0 0 17px;
      box-shadow: none;
      color: #FFF;
      cursor: pointer;
      height: 35px;
      margin-left: 10px; // new
      padding: .857rem .5rem;

      &:hover {
        .local-events-inactive {
          background-image: url('/assets/images/local-events-active.svg');
          height: 19px;
          width: 17px;
        }

        .onboarding-members-inactive {
          background-image: url('/assets/images/onboarding-icon-active.svg');
          height: 15px;
          width: 19px;
        }

        .local-announcment-inactive {
          background-image: url('/assets/images/local-announcement-active.svg');
          height: 17px;
          width: 19px;
        }

        .local-upcoming-work-inactive {
          background-image: url('/assets/images/upcoming-active.svg');
          height: 17px;
          width: 17px;
        }

        .officials-committees-inactive {
          background-image: url('/assets/images/officials-committees-active.svg');
          height: 17px;
          width: 18px;
        }

        .notification-inactive {
          background-image: url('/assets/images/notification-active.svg');
          height: 17px;
          width: 14px;
        }

        .company-inactive {
          background-image: url('/assets/images/Company-Icon-Selected.svg');
          height: 17px;
          width: 14px;
        }
      }
    }

    &.p-highlight {
      a {
        background: #FFF;
        background-image: linear-gradient(to right, #75C5DE 2%, #FFF 72%);
        border-color: #FFF;
        border-radius: 17px 0 0 17px;
        color: #093A83;
        height: 35px;
        margin-left: 10px; // new

        &:hover {
          color: #093A83;
        }
      }

      &:not(.p-disabled) {
        a {
          &:hover {
            background: #FFF;
            background-image: linear-gradient(to right, #75C5DE 2%, #FFF 72%);
            border-color: #FFF;
            color: #093A83;
            margin-left: 10px; // new
          }
        }
      }

    }
  }

}

.profile-btn-2 {
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 111px;

  img {
    height: 18px;
    width: 28px;
  }
}

.users-icon {
  background-image: url('/assets/images/local-announcement1.png');
  height: 18px;
  width: 20px;
}

.local-announcement {
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 200px;
}

.local-upcoming-work {
  background-image: url('/assets/images/upcoming2.png');
  height: 17px;
  width: 17px;

}

.upcoming-icon {
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 244px;

  img {
    height: 20px;
    width: 20px;
  }
}


.onboarding-members {
  background-image: url('/assets/images/onboarding-icon-active.svg');
  height: 15px;
  width: 19px;
}


.onboarding-members-inactive {
  background-image: url('/assets/images/onboarding-icon.svg');
  height: 15px;
  width: 19px;
}

.members {
  background-image: url('/assets/images/members-icon.svg');
  height: 15px;
  width: 19px;
}


.members-inactive {
  background-image: url('/assets/images/members-icon-inactive.svg');
  height: 15px;
  width: 19px;
}

.local-events {
  background-image: url('/assets/images/local-events-active.svg');
  height: 19px;
  width: 17px;
}

.local-events-inactive {
  background-image: url('/assets/images/local-events-inactive-icon.svg');
  height: 19px;
  width: 17px;

  &:hover {
    background-image: url('/assets/images/local-events-icon.png');
  }
}

.local-announcment-inactive {
  background-image: url('/assets/images/local-announcement-inactive.svg');
  height: 17px;
  margin-left: 9px;
  width: 19px;
}

.local-announcment-active {
  background-image: url('/assets/images/local-announcement-active.svg');
  height: 17px;
  width: 19px;
}

.local-upcoming-work-inactive {
  background-image: url('/assets/images/upcoming-inactive.svg');
  height: 19px;
  width: 19px;
}

.local-upcoming-work-active {
  background-image: url('/assets/images/upcoming-active.svg');
  height: 17px;
  width: 17px;
}

.officials-committees-inactive {
  background-image: url('/assets/images/officials-committees-inactive.svg');
  height: 17px;
  width: 18px;
}

.officials-committees-active {
  background-image: url('/assets/images/officials-committees-active.svg');
  height: 17px;
  width: 18px;
}

.notification-active {
  background-image: url('/assets/images/notification-active.svg');
  height: 17px;
  margin-left: 2px;
  width: 14px;
}

.notification-inactive {
  background-image: url('/assets/images/notification.svg');
  height: 17px;
  margin-left: 2px;
  width: 14px;
}

.company-inactive {
  background-image: url('/assets/images/Company-Icon-Non-select.svg');
  height: 17px;
  margin-left: 2px;
  width: 14px;
}

.company-active {
  background-image: url('/assets/images/Company-Icon-Selected.svg');
  height: 17px;
  margin-left: 2px;
  width: 14px;
}


.local-events-icon {
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 155px;

  img {
    height: 19px;
    width: 17px;
  }
}

.officials {
  background-image: url('/assets/images/official2.png');
  height: 20px;
  width: 20px;
}

.official-icon {
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 289px;

  img {
    height: 20px;
    width: 20px;
  }
}

@media screen and (min-width: 1600px) {
  .left-panel {
    width: 15%;
  }

  .right-panel {
    width: 85%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .left-panel {
    width: 20%;
  }

  .right-panel {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .left-panel {
    width: 22%;
  }

  .right-panel {
    width: 78%;
  }
}
